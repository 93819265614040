<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";
import categoryUploadComponent from "@/components/categoryUploadComponent";
import simplebar from "simplebar-vue";
// import btnComponent from "@/components/btnComponent";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "สอบถามข้อมูลเครดิต",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ChangeDate,
    simplebar,
    categoryUploadComponent
    // btnComponent,
  },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขข้อมูลบันทึกผลข้อมูลเครดิต",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: "บันทึกผลข้อมูลเครดิต",
          active: false,
          href: "/approve-credit-inquire",
        },
        {
          text: "แก้ไขข้อมูลบันทึกผลข้อมูลเครดิต",
          active: true,
        },
      ],
      urlShow: "api/approve-credit-inquire/file",
      urlUpload: "api/approve-credit-inquire/file/upload",
      urlFullsize: "api/approve-credit-inquire/file/full-size",
      urlDelete: "api/approve-credit-inquire/file/delete",
      paramsShow: {
        params: {
          inquireId: atob(this.$route.params.inquireId),
        },
      },
      paramsDel: {
        data: {
          inquireId: atob(this.$route.params.inquireId),
          fileId: "",
        },
      },
      paramsFull: {
        params: {
          inquireId: atob(this.$route.params.inquireId),
          fileId: "",
        },
      },
      paramsSending: "inquireId",
      brand: "",
      bookingDcPrice: 0,
      dropzoneOptions: {
        url:
          `${appConfig.api.baseURL}api/approve-credit-inquire/upload`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        acceptedFiles: "image/*",
        uploadMultiple: false,
        paramName: "image",
        init: function() {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
      // branchId:'',
      bookingDate: "",
      qnDetails: "",
      part: "",
      dcPerAccy: 0,
      amountAccy: 1,
      nameState: null,
      typeQnDetails: "ACCY",
      bookingVatPer: 7.0,
      bookingDcPer: 0,
      bookingNetPrice: 0,
      bookingVatPrice: 0,
      bookingGrandTotalPrice: 0,
      resalert: "",
      rowsPO: [],

      branchIdSearch: "",
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      interiorCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      customerG: "",
      loading: false,
      pageOptions: [10, 25, 50, 100],

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณาตรวจสอบข้อมูลอีกครั้ง",

      userBranch: "",
      rowMasterBrands: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      branch: "",
      rowProducts: [],
      rowsCustomers: [],
      rowsFin: [],
      rowsAct: [],
      rowsCampiagn: [],
      rowsExColor: [],
      rowsInColor: [],
      rowsAccy: [],
      rowsExtra: [],
      optionBranch: [],

      qnAccy: [],
      qnPm: [],
      qnOth: [],

      qnTotalPrice: 0,
      qnPartTotalPrice: 0,
      qnPartDcPer: 0,
      qnPartDcPrice: 0,
      qnPartNetPrice: 0,
      qnExtraTotalPrice: 0,
      qnExtraDcPer: 0,
      qnExtraDcPrice: 0,
      qnExtraNetPrice: 0,

      qnDcPrice: 0,

      qnVatPer: 7.0,
      qnDcPer: 0,
      qnNetPrice: 0,
      qnVatPrice: 0,
      qnGrandTotalPrice: 0,

      creditStatus: "",
      optionStatus: [
        {
          nameTh: "อนุมัติ",
          status: "APV",
        },
        {
          nameTh: "ไม่อนุมัติ",
          status: "NAPV",
        },
        {
          nameTh: "ขอเอกสารเพิ่มเติม",
          status: "RQDOC",
        },
      ],
      filter: {
        bookingCode: "",
        qnCode: "",
        inquireCode: "",
        customer: "",
        startDate: "",
        endDate: "",
        status: "",
        userBranchId: "",
      },
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "qnCode",
          sortable: true,
          label: "qnCode",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "รหัสจอง",
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "productNameTh",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        {
          key: "bookingDate",
          sortable: true,
          label: "วันที่จอง",
        },
        {
          key: "grandTotalPrice",
          label: "ราคา",
        },
        {
          key: "status",
          label: "สถานะ",
          class: "text-center",
        },
        {
          key: "branchName",
          label: "สาขา",
        },
      ],
      fieldsInst: [
        {
          key: "periodNo",
          label: "งวดที่",
        },
        {
          key: "dueDate",
          label: "วันที่ชำระ",
        },
        {
          key: "price",
          label: "ยอดชำระ",
        },
        {
          key: "vatPrice",
          label: "% ภาษี",
        },
        {
          key: "totalPrice",
          label: "สุทธิ",
        },
        // {
        //   key: "action",
        //   label: "ลบ",
        // },
      ],
      fieldsGuarantor: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "customerNameTh",
          label: "ชื่อผู้ค้ำประกัน",
        },
        {
          key: "customerFamilyNameTh",
          label: "นามสกุลผู้ค้ำประกัน",
        },
        {
          key: "action",
          label: "ลบ",
        },
      ],
      rowSaleCondition: [
        {
          scId: "C",
          scName: "เงินสด",
        },
        {
          scId: "I",
          scName: "เช่าซื้อ / ผ่อน",
        },
        {
          scId: "CD",
          scName: "สด / เครดิต",
        },
        {
          scId: "OI",
          scName: "เช่าซื้อสถาบันอื่น ๆ",
        },
      ],
      postType: [
        {
          ptId: "R",
          nameTh: "รับเอง",
        },
        {
          ptId: "S",
          nameTh: "จัดส่ง",
        },
      ],
      taxTypeOptions: [
        {
          ttId: "T",
          nameTh: "มีภาษี",
        },
        {
          ttId: "NT",
          nameTh: "ไม่มีภาษี",
        },
      ],

      activity: "",

      approveNote: "",
      inquireCode: "",
      inquireDate: "",
      status: "",
      statusInquire: "",
      inquireNote: "",
      inquireNoteCreate: "",
      rowsMessage: [],
      textMessage: "",

      bookingAccy: [],
      bookingPm: [],
      bookingOth: [],
      rowsGuarantor: [],
      rowsInstallment: [],
      rowsInst: [],

      image: null,
      rowsImage: [],

      ttAccy: 0,
      ttPm: 0,
      ttOTH: 0,

      totalPricex: 0,
      bookingTotalPrice: 0,
      bookingPartTotalPrice: 0,
      bookingPartDcPer: 0,
      bookingPartDcPrice: 0,
      bookingPartNetPrice: 0,
      bookingExtraTotalPrice: 0,
      bookingExtraDcPer: 0,
      bookingExtraDcPrice: 0,
      bookingExtraNetPrice: 0,

      vehicleTotalPrice: 0,
      vehicleDcPer: 0,
      vehicleDcPrice: 0,
      vehicleNetPrice: 0,
      partTotalPrice: 0,
      partDcPer: 0,
      partDcPrice: 0,
      partNetPrice: 0,
      extraTotalPrice: 0,
      extraDcPer: 0,
      extraDcPrice: 0,
      extraNetPrice: 0,
      totalPrice: 0,
      dcPer: 0,
      dcPrice: 0,
      netPrice: 0,
      vatPer: 7,
      vatPrice: 0,
      grandTotalPrice: 0,
      saleCondition: "",
      downPayment: 0,
      totalFinancing: 0,
      fiId: "",
      interest: 0,
      numPeriods: 0,
      installment: 0,
      note: "",
      prodId: "",
      actId: "",
      exteriorColorId: "",
      interiorColorId: "",
      campaignId: "",
      statusQn: "",
      accyCode: "",
      partQnDt: [],
      partQnDtSum: [],
      exCode: "",
      selectedExtra: "",
      ex: "",
      recommenderId: "",
      addrId: "",
      vehicleId: "",
      referralFee: 0,
      licensePlateDelivery: "",
      productDelivery: "",
      taxType: "",
      installmentDate: "",
      rowsVm: [],
      rowsArr: [],
    };
  },
  validations: {
    creditStatus: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    if (this.$route.params.inquireId) {
      this.showDetail();
    }
  },
  created() {
    // this.getMasterBrand();

    // this.showBookingDetails();
    // this.getGuarantor();
    // this.getInstallment();

    this.getLocalData();
    // this.getProduct();
    // this.getCustomer();
    // this.getFin();
    // this.getActivity();
    // this.getCampaign();
    // this.getExColor();
    // this.getInColor();
    // this.getAccy();
    // this.getExtra();
    // this.getVehicle();
    // this.getCustomerArr();
  },
  methods: {
    customLabelCustomerArr({
      buildingNumber,
      districtNameTh,
      amphurNameEn,
      provinceNameTh,
      postCode,
    }) {
      return `บ้านเลขที่ ${buildingNumber == null ? "-" : buildingNumber}
      ตำบล ${districtNameTh == null ? "-" : districtNameTh} ,
      อำเภอ ${amphurNameEn == null ? "-" : amphurNameEn},
       ${provinceNameTh == null ? "-" : provinceNameTh} ,
       ${postCode == null ? "-" : postCode}`;
    },
    customLabelVehicle({
      stockNameTh,
      vin,
      productNameTh,
      engineNumber,
      chassisNumber,
      exteriorColorNameTh,
    }) {
      return `[${stockNameTh != null ? stockNameTh : "-"}]

      ${productNameTh}
      - สีภายนอก : ${exteriorColorNameTh == null ? "-" : exteriorColorNameTh},
      เลขตัวถัง : ${vin == null ? "-" : vin} ,
        เลขเครื่อง :${engineNumber == null ? "-" : engineNumber},
        เลขคัสซี : ${chassisNumber == null ? "-" : chassisNumber}`;
    },
    getRecommenderId() {
      // this.overlayFlag = true;
      useNetw
        .get("api/sale-quotation/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            nameTh: this.recommenderId.nameTh,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCustomers = response.data.data;
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    // getCustomerArr() {
    //   // this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-customer-address", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //         cusId: this.nameTh.cusId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsArr = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    // getVehicle() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-stock-vehicle", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //         prodId: this.prodId.prodId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsVm = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    // processInst() {
    //   const formDataG = new FormData();
    //   formDataG.append("bookingId", this.$route.params.bookingId);

    //   useNetw
    //     .post("api/vehicle-booking/installment/process", formDataG)
    //     .then((response) => {
    //       this.showAlert(response.data.message);
    //       //   this.showBookingDetails();
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       // this.overlayFlag = false;
    //       // this.$router.push({ name: "branch-wages" });
    //     });
    // },
    postGuarantor(customerG) {
      const formDataG = new FormData();
      formDataG.append("bookingId", this.$route.params.bookingId);
      formDataG.append("cusId", customerG.cusId);

      useNetw
        .post("api/vehicle-booking/guarantor/store", formDataG)
        .then((response) => {
          this.showAlert(response.data.message);
          //   this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    changeType(type) {
      this.typeQnDetails = type;
    },
    postEx(ex) {
      let arr = [];
      this.qnDetails.forEach((item) => {
        arr.push(item.extraId);
      });
      if (!arr.includes(ex.extraId)) {
        this.qnDetails.push(ex);
      }

      this.calTotal();

      const formDataPart = new FormData();
      formDataPart.append("bookingId", this.$route.params.bookingId);
      // formDataPart.append("partId", null);
      formDataPart.append("extraId", ex.extraId);
      formDataPart.append("amount", this.amountAccy);
      formDataPart.append("pricePerOne", ex.price);
      formDataPart.append("totalPrice", ex.price * this.amountAccy);
      formDataPart.append("dcPrice", ex.price * (this.dcPerAccy / 100));
      formDataPart.append("dcPer", this.dcPerAccy);
      formDataPart.append("netPrice", ex.price * this.amountAccy);
      formDataPart.append("vatPer", this.vatPer);
      formDataPart.append("vatPrice", 0);
      formDataPart.append("grandTotalPrice", ex.price * this.amountAccy);

      formDataPart.append("type", "OTH");

      formDataPart.append("bookingPartTotalPrice", this.ttAccy);
      formDataPart.append("bookingPartDcPer", 0);
      formDataPart.append("bookingPartDcPrice", 0);
      formDataPart.append("bookingPartNetPrice", this.ttAccy);
      formDataPart.append("bookingExtraTotalPrice", parseFloat(this.ttOTH));
      formDataPart.append("bookingExtraDcPer", 0);
      formDataPart.append("bookingExtraDcPrice", 0);
      formDataPart.append("bookingExtraNetPrice", parseFloat(this.ttOTH));
      formDataPart.append("grandTotalPrice", this.totalPrice);

      formDataPart.append("bookingTotalPrice", this.totalPrice);
      formDataPart.append("bookingDcPer", this.bookingDcPer);
      formDataPart.append("bookingDcPrice", this.bookingDcPrice);
      formDataPart.append("bookingNetPrice", this.bookingNetPrice);
      formDataPart.append("bookingVatPer", this.bookingVatPer);
      formDataPart.append("bookingVatPrice", this.bookingVatPrice);

      formDataPart.append(
        "bookingGrandTotalPrice",
        parseFloat(this.bookingGrandTotalPrice)
      );

      useNetw
        .post("api/vehicle-booking/detail/store", formDataPart)
        .then((response) => {
          this.$refs.modalAmoutEx.hide();
          this.showAlert(response.data.message);
          //   this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs["modalAmout"].hide();
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    calAll() {
      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
        }
      });
    },
    delInst(iperiodId) {
      useNetw
        .delete("api/vehicle-booking/installment/delete", {
          data: {
            iperiodId: iperiodId,
            // partId: rowRT.partId,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          //   this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    delGuarantor(guarantorId) {
      useNetw
        .delete("api/vehicle-booking/guarantor/delete", {
          data: {
            guarantorId: guarantorId,
            // partId: rowRT.partId,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          //   this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    conformDelInst(iperiodId) {
      Swal.fire({
        title: "ต้องการลบงวดการชำระใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delInst(iperiodId);
        }
      });
    },

    confirmDelGuarantor(guarantorId) {
      Swal.fire({
        title: "ต้องการลบผู้ค้ำประกันใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delGuarantor(guarantorId);
        }
      });
    },
    delAccy(rowRT, index) {
      // this.selectDataPO = rowsRC.item;

      this.qnDetails.splice(index, 1);
      this.calAll();
      this.calTotal();
      useNetw
        .delete("api/vehicle-booking/detail/delete", {
          data: {
            qndId: rowRT.qndId,
            // partId: rowRT.partId,

            bookingPartTotalPrice: this.ttAccy,
            bookingPartDcPer: 0,
            bookingPartDcPrice: 0,
            bookingPartNetPrice: this.ttAccy,
            bookingExtraTotalPrice: this.ttOTH,
            bookingExtraDcPer: 0,
            bookingExtraDcPrice: 0,
            bookingExtraNetPrice: this.ttOTH,
            bookingTotalPrice: this.bookingTotalPrice,
            bookingDcPer: this.bookingDcPer,
            bookingDcPrice: this.bookingDcPrice,
            bookingNetPrice: this.bookingNetPrice,
            bookingVatPer: this.bookingVatPer,
            bookingVatPrice: this.bookingVatPrice,
            bookingGrandTotalPrice: parseFloat(this.bookingGrandTotalPrice),
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          //   this.showBookingDetails();
          // this.getTotalPriceRT;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    ConfirmDelAccy(rowsAccy, index) {
      Swal.fire({
        title: "ต้องการลบใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delAccy(rowsAccy[index], index);
        }
      });
    },
    calculatePQnDt(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(this.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.partQnDt = part;

      var bookingPartTotalPrice = totalPriceCal;
      var bookingPartDcPer = 0;
      var bookingPartDcPrice = 0;
      var bookingPartNetPrice = part.netPrice;
      var bookingExtraTotalPrice = 0;
      var bookingExtraDcPer = 0;
      var bookingExtraDcPrice = 0;
      var bookingExtraNetPrice = 0;

      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy + parseFloat(item.netPrice);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH + parseFloat(item.netPrice);
        }
      });

      if (this.typeQnDetails == "ACCY") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typeQnDetails == "PM") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typeQnDetails == "OTH") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      }
      var x = [];
      x.push({
        bookingPartTotalPrice: bookingPartTotalPrice,
        bookingPartDcPer: bookingPartDcPer,
        bookingPartDcPrice: bookingPartDcPrice,
        bookingPartNetPrice: bookingPartNetPrice,
        bookingExtraTotalPrice: bookingExtraTotalPrice,
        bookingExtraDcPer: bookingExtraDcPer,
        bookingExtraDcPrice: bookingExtraDcPrice,
        bookingExtraNetPrice: bookingExtraNetPrice,
      });
      this.partQnDtSum = x;
      this.totalPricex = totalPriceCal;

      this.calTotal();
    },

    calculateQnDt(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(this.amountAccy);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(this.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.partQnDt = part;

      var bookingPartTotalPrice = totalPriceCal;
      var bookingPartDcPer = 0;
      var bookingPartDcPrice = 0;
      var bookingPartNetPrice = part.netPrice;
      var bookingExtraTotalPrice = 0;
      var bookingExtraDcPer = 0;
      var bookingExtraDcPrice = 0;
      var bookingExtraNetPrice = 0;

      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
        }
      });

      if (this.typeQnDetails == "ACCY") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typeQnDetails == "PM") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typeQnDetails == "OTH") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      }
      var x = [];
      x.push({
        bookingPartTotalPrice: bookingPartTotalPrice,
        bookingPartDcPer: bookingPartDcPer,
        bookingPartDcPrice: bookingPartDcPrice,
        bookingPartNetPrice: bookingPartNetPrice,
        bookingExtraTotalPrice: bookingExtraTotalPrice,
        bookingExtraDcPer: bookingExtraDcPer,
        bookingExtraDcPrice: bookingExtraDcPrice,
        bookingExtraNetPrice: bookingExtraNetPrice,
      });
      this.partQnDtSum = x;
      this.totalPricex = totalPriceCal;

      this.calTotal();
    },
    calTotal() {
      this.ttAccy = 0;
      this.ttOTH = 0;

      this.qnDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
        }
      });
      this.partTotalPrice = parseFloat(this.ttAccy);
      this.extraTotalPrice = parseFloat(this.ttOTH);

      this.vehicleDcPrice =
        parseFloat(this.vehicleTotalPrice) *
        parseFloat(this.vehicleDcPer / 100);
      this.vehicleNetPrice =
        parseFloat(this.vehicleTotalPrice) - parseFloat(this.vehicleDcPrice);
      this.partDcPrice =
        parseFloat(this.ttAccy) * parseFloat(this.partDcPer / 100);
      this.partNetPrice =
        parseFloat(this.ttAccy) - parseFloat(this.partDcPrice);
      this.extraDcPrice =
        parseFloat(this.ttOTH) * parseFloat(this.extraDcPer / 100);
      this.extraNetPrice =
        parseFloat(this.ttOTH) - parseFloat(this.extraDcPrice);

      var qnTotalPrice, qnDcPrice, qnNetPrice, qnVatPrice, qnGrandTotalPrice;

      qnTotalPrice =
        parseFloat(this.vehicleNetPrice) +
        parseFloat(this.partNetPrice) +
        parseFloat(this.extraNetPrice);
      this.qnTotalPrice = parseFloat(qnTotalPrice);
      qnDcPrice = parseFloat(this.qnDcPer / 100) * parseFloat(qnTotalPrice);
      this.qnDcPrice = parseFloat(qnDcPrice);
      qnNetPrice = parseFloat(qnTotalPrice) - parseFloat(qnDcPrice);
      this.qnNetPrice = parseFloat(qnNetPrice);
      qnVatPrice = parseFloat(this.qnVatPer / 100) * parseFloat(qnNetPrice);
      this.qnVatPrice = parseFloat(qnVatPrice);
      qnGrandTotalPrice = parseFloat(qnNetPrice) + parseFloat(qnVatPrice);
      this.qnGrandTotalPrice = parseFloat(qnGrandTotalPrice);

      this.totalFinancing =
        parseFloat(this.qnGrandTotalPrice) - parseFloat(this.downPayment);
    },
    putAccy(
      qndid,
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      vatPer,
      vatPrice,
      grandTotalPrice,
      extraId
    ) {
      (this.amountAccy = amount), this.calTotal();
      useNetw
        .put("api/vehicle-booking/detail/update", {
          bookingId: this.$route.params.bookingId,

          qndId: qndid,
          partId: parseInt(partId),
          amount: parseInt(amount),
          extraId: extraId,
          pricePerOne: sellPrice,
          totalPrice: totalPrice,
          dcPer: dcPer,
          dcPrice: dcPrice,
          netPrice: netPrice,
          vatPer: vatPer,
          vatPrice: vatPrice,
          grandTotalPrice: parseInt(amount) * sellPrice,
          type: this.typeQnDetails,
          // pickingAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,
          // pickingAccruedAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,
          bookingPartTotalPrice: this.ttAccy,
          bookingPartDcPer: 0,
          bookingPartDcPrice: 0,
          bookingPartNetPrice: this.ttAccy,
          bookingExtraTotalPrice: this.ttOTH,
          bookingExtraDcPer: 0,
          bookingExtraDcPrice: 0,
          bookingExtraNetPrice: this.ttOTH,

          bookingTotalPrice: this.bookingTotalPrice,
          bookingDcPer: this.bookingDcPer,
          bookingDcPrice: this.bookingDcPrice,
          bookingNetPrice: this.bookingNetPrice,
          bookingVatPer: this.bookingVatPer,
          bookingVatPrice: this.bookingVatPrice,
          bookingGrandTotalPrice: parseFloat(this.bookingGrandTotalPrice),
        })
        .then((response) => {
          this.showAlert(response.data.message);

          //   this.showBookingDetails;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },

    postAccy(part) {
      let arr = [];
      this.qnDetails.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.qnDetails.push(part);
      }

      this.calculateQnDt(part);
      this.calTotal();

      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = this.selectedPart.sellPrice * this.amountAccy;
      part.netPrice;

      const formDataPart = new FormData();
      formDataPart.append("bookingId", this.$route.params.bookingId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", this.amountAccy);
      formDataPart.append("pricePerOne", part.pricePerOne);
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.totalPrice * (this.dcPerAccy / 100));
      formDataPart.append("dcPer", this.dcPerAccy);
      formDataPart.append("netPrice", part.totalPrice);
      formDataPart.append("vatPer", this.vatPer);
      formDataPart.append("vatPrice", part.vatPrice);
      formDataPart.append("grandTotalPrice", part.totalPrice);

      formDataPart.append("type", this.typeQnDetails);

      formDataPart.append("bookingPartTotalPrice", part.grandTotalPrice);
      formDataPart.append(
        "bookingPartDcPer",
        this.partQnDtSum[0].bookingPartDcPer
      );
      formDataPart.append(
        "bookingPartDcPrice",
        this.partQnDtSum[0].bookingPartDcPrice
      );
      formDataPart.append(
        "bookingPartNetPrice",
        this.partQnDtSum[0].bookingPartNetPrice
      );
      formDataPart.append(
        "bookingExtraTotalPrice",
        this.partQnDtSum[0].bookingExtraTotalPrice
      );
      formDataPart.append(
        "bookingExtraDcPer",
        this.partQnDtSum[0].bookingExtraDcPer
      );
      formDataPart.append(
        "bookingExtraDcPrice",
        this.partQnDtSum[0].bookingExtraDcPrice
      );
      formDataPart.append(
        "bookingExtraNetPrice",
        this.partQnDtSum[0].bookingExtraNetPrice
      );
      formDataPart.append(
        "grandTotalPrice",
        this.partQnDtSum[0].bookingExtraNetPrice
      );

      formDataPart.append("bookingTotalPrice", this.bookingTotalPrice);
      formDataPart.append("bookingDcPer", this.bookingDcPer);
      formDataPart.append("bookingDcPrice", this.bookingDcPrice);
      formDataPart.append("bookingNetPrice", this.bookingNetPrice);
      formDataPart.append("bookingVatPer", this.bookingVatPer);
      formDataPart.append("bookingVatPrice", this.bookingVatPrice);

      formDataPart.append(
        "bookingGrandTotalPrice",
        parseFloat(this.bookingGrandTotalPrice)
      );

      useNetw
        .post("api/vehicle-booking/detail/store", formDataPart)
        .then((response) => {
          this.showAlert(response.data.message);
          //   this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs["modalAmout"].hide();
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    showModal(option) {
      this.selectedPart = option;
      this.part = option;
      this.part.partCode = option.partCode;
      this.part.pricePerOne = option.sellPrice;
      this.part.totalPrice = option.sellPrice;
      this.part.netPrice = option.sellPrice;
      this.part.nameTh = option.nameTh;
      this.part.partId = option.partId;
      this.$refs.modalAmout.show(option);
    },

    showModalEx(option) {
      this.selectedExtra = option;
      this.ex = option;
      this.ex.partCode = option.partCode;
      this.ex.pricePerOne = option.sellPrice;
      this.ex.totalPrice = option.sellPrice;
      this.ex.netPrice = option.sellPrice;
      this.ex.nameTh = option.nameTh;
      this.ex.partId = option.partId;
      this.$refs.modalAmoutEx.show(option);
    },
    // showBookingDetails() {
    //   this.bookingAccy = [];
    //   this.bookingPm = [];
    //   this.bookingOth = [];
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/detail", {
    //       params: {
    //         bookingId: this.$route.params.bookingId,
    //       },
    //     })
    //     .then((response) => {
    //       this.qnDetails = response.data.data;
    //       this.qnDetails.forEach((item) => {
    //         if (item.type == "ACCY") {
    //           this.bookingAccy.push(item);
    //         } else if (item.type == "PM") {
    //           this.bookingPm.push(item);
    //         } else if (item.type == "OTH") {
    //           this.bookingOth.push(item);
    //         }
    //       });
    //       this.calTotal();
    //       this.getGuarantor();
    //       this.getInstallment();
    //     })
    //     .catch((err) => {
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           // appConfig.swal.title.Unauthorization,
    //           appConfig.swal.type.error
    //           // appConfig.swal.title.Unauthorization
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    showDetail() {
      this.overlayFlag = true;
      useNetw
        .get("api/approve-credit-inquire/show", {
          params: {
            inquireId: atob(this.$route.params.inquireId),
          },
        })
        .then((response) => {
          this.statusQn = response.data.branchVehicleBooking.status;
          this.branch = {
            branchId: response.data.branchVehicleBooking.branchId,
            nameTh: response.data.branchVehicleBooking.branchName,
          };
          (this.nameTh = {
            cusId: response.data.branchVehicleBooking.cusId,
            nameTh: response.data.branchVehicleBooking.customerNameTh,
            familyNameTh:
              response.data.branchVehicleBooking.customerFamilyNameTh,
          }),
            (this.bookingDate = response.data.branchVehicleBooking.bookingDate);
          this.activity = {
            actId: response.data.branchVehicleBooking.actId,
            nameTh: response.data.branchVehicleBooking.actId,
          };
          this.prodId = {
            prodId: response.data.branchVehicleBooking.prodId,
            prodCode: response.data.branchVehicleBooking.brandNameEn,
            nameTh: response.data.branchVehicleBooking.productNameTh,
            nameEn: response.data.branchVehicleBooking.productNameEn,
          };
          this.exteriorColorId = {
            exteriorColorId: response.data.branchVehicleBooking.exteriorColorId,
            nameTh: response.data.branchVehicleBooking.exteriorColorNameTh,
            nameEn: response.data.branchVehicleBooking.exteriorColorNameEn,
          };
          this.interiorColorId = {
            interiorColorId: response.data.branchVehicleBooking.interiorColorId,
            nameTh: response.data.branchVehicleBooking.interiorColorNameTh,
            nameEn: response.data.branchVehicleBooking.interiorColorNameEn,
          };
          this.campaignId = {
            campaignId: response.data.branchVehicleBooking.campaignId,
            nameTh: response.data.branchVehicleBooking.campaignNameTh,
            nameEn: response.data.branchVehicleBooking.campaignNameEn,
          };
          this.vehicleTotalPrice =
            response.data.branchVehicleBooking.vehicleTotalPrice;
          this.vehicleDcPer = response.data.branchVehicleBooking.vehicleDcPer;
          this.vehicleDcPrice =
            response.data.branchVehicleBooking.vehicleDcPrice;
          this.vehicleNetPrice =
            response.data.branchVehicleBooking.vehicleNetPrice;

          this.saleCondition = {
            scId: response.data.branchVehicleBooking.saleCondition,
            scName: response.data.branchVehicleBooking.saleCondition,
          };
          this.downPayment = response.data.branchVehicleBooking.downPayment;
          this.totalFinancing =
            response.data.branchVehicleBooking.totalFinancing;
          this.fiId = {
            fiId: response.data.branchVehicleBooking.fiId,
            nameTh: response.data.branchVehicleBooking.fiId,
          };
          this.interest = response.data.branchVehicleBooking.interest;
          (this.numPeriods = response.data.branchVehicleBooking.numPeriods),
            (this.installment = response.data.branchVehicleBooking.installment),
            (this.note =
              response.data.branchVehicleBooking.note == null
                ? ""
                : response.data.branchVehicleBooking.note);

          this.recommenderId = {
            recommenderId: response.data.branchVehicleBooking.recommenderId,
            nameTh: response.data.branchVehicleBooking.recommenderNameTh,
            familyNameTh:
              response.data.branchVehicleBooking.recommenderFamilyNameTh,
          };
          this.addrId = {
            addrId: response.data.branchVehicleBooking.addrId,
          };
          this.vehicleId = {
            vehicleId: response.data.branchVehicleBooking.vehicleId,
            stockNameTh: response.data.branchVehicleBooking.stockNameTh,
            vin: response.data.branchVehicleBooking.vin,
            chassisNumber: response.data.branchVehicleBooking.chassisNumber,
            engineNumber: response.data.branchVehicleBooking.engineNumber,
            productNameTh: response.data.branchVehicleBooking.productNameTh,
            exteriorColorNameTh:
              response.data.branchVehicleBooking.exteriorColorNameTh,
          };
          this.referralFee = response.data.branchVehicleBooking.referralFee;
          this.licensePlateDelivery = {
            ptId: response.data.branchVehicleBooking.licensePlateDelivery,
            nameTh:
              response.data.branchVehicleBooking.licensePlateDelivery == "S"
                ? "จัดส่ง"
                : "รับเอง",
          };
          this.productDelivery = {
            ptId: response.data.branchVehicleBooking.productDelivery,
            nameTh:
              response.data.branchVehicleBooking.productDelivery == "S"
                ? "จัดส่ง"
                : "รับเอง",
          };
          this.taxType = {
            ttId: response.data.branchVehicleBooking.taxType,
            nameTh:
              response.data.branchVehicleBooking.taxType == "T"
                ? "มีภาษี"
                : "ไม่มีมีภาษี",
          };
          this.installmentDate =
            response.data.branchVehicleBooking.installmentDate;

          this.qnDetails = response.data.branchVehicleBookingDetail;
          this.qnDetails.forEach((item) => {
            if (item.type == "ACCY") {
              this.qnAccy.push(item);
            } else if (item.type == "PM") {
              this.qnPm.push(item);
            } else if (item.type == "OTH") {
              this.qnOth.push(item);
            }
          });

          this.rowsInst = response.data.branchVehicleBookingInstallmentPeriod;

          this.rowsGuarantor = response.data.branchVehicleBookingGuarantor;

          this.inquireNote = response.data.master.inquireNote;
          this.approveNote = response.data.master.approveNote;
          this.inquireDate = response.data.master.inquireDate;
          this.inquireCode = response.data.master.inquireCode;
          this.statusInquire = response.data.master.status;

          //   this.status = response.data.branchVehicleBooking.status;

          this.getImage();
          this.getMessage();
          this.calTotal();
          // referralFee
          // licensePlateDelivery
          // productDelivery
          // taxType
          //
          this.overlayFlag = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    setProduct(vehicle) {
      this.vehicleTotalPrice = parseInt(vehicle.price);
      this.calProd();
    },
    calProd() {
      var dcPrice;
      var netPrice;
      var totalFinancing;
      var installments;

      if (!isNaN()) {
        // console.log("No");
      } else {
        dcPrice =
          this.vehicleTotalPrice * (parseFloat(this.vehicleDcPer) / 100);
        netPrice = this.vehicleTotalPrice - dcPrice;
        totalFinancing = netPrice - parseFloat(this.downPayment);
        installments =
          (totalFinancing / this.numPeriods) * (1 + this.interest / 100);

        this.vehicleDcPrice = dcPrice;
        this.vehicleNetPrice = netPrice;
        this.totalFinancing = totalFinancing;
        this.installment = installments.toFixed(2);
      }
    },

    customLabelProduct({ prodCode, nameEn }) {
      return `[${prodCode}]  ${nameEn}`;
    },
    customLabelCustomer({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    customLabelAccy({ partCode, nameTh }) {
      return `[${partCode != null ? " " + partCode : ""}] - ${
        nameTh != null ? nameTh : ""
      }`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitSalq();
      }
    },

    tooltipFormSubmit() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDetail();
      }
    },

    // getInColor() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-interior-color", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsInColor = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getGuarantor() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/guarantor", {
    //       params: {
    //         bookingId: this.$route.params.bookingId,
    //         // page: this.currentPage,
    //         // perPage: 100,
    //         // branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsGuarantor = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getInstallment() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/installment", {
    //       params: {
    //         bookingId: this.$route.params.bookingId,
    //         // page: this.currentPage,
    //         // perPage: 100,
    //         // branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsInst = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getExtra() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-extra", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsExtra = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getExColor() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-exterior-color", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsExColor = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getCampaign() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-campaign", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsCampiagn = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getActivity() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-activity", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsAct = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getFin() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-financial-institution", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsFin = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getCustomer() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-customer", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsCustomers = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getAccy() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-part", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: 100,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowsAccy = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    // getProduct() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/vehicle-booking/branch-product		", {
    //       params: {
    //         page: this.currentPage,
    //         perPage: this.perPage,
    //         branchId: this.branchId,
    //       },
    //     }) // ?เอามาจากไหน

    //     .then((response) => {
    //       this.rowProducts = response.data.data;
    //     })
    //     .catch((err) => {
    //       //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
    //       if (err.response.status === 401) {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(appConfig.swal.title.Unauthorization),
    //           appConfig.swal.type.error
    //         );
    //         this.$router.push("/login");
    //       }
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },

    submitSalq: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/vehicle-booking/update", {
          bookingId: this.$route.params.bookingId,
          branchId: this.branch.branchId,
          cusId: this.nameTh.cusId,
          bookingDate: this.bookingDate,
          vehicleTotalPrice: this.vehicleTotalPrice,
          vehicleDcPer: this.vehicleDcPer,
          vehicleDcPrice: this.vehicleDcPrice,
          vehicleNetPrice: this.vehicleNetPrice,
          partTotalPrice: this.ttAccy,
          partDcPer: 0,
          partDcPrice: 0,
          partNetPrice: this.ttAccy,
          extraTotalPrice: this.ttOTH,
          extraDcPer: 0,
          extraDcPrice: 0,
          extraNetPrice: this.ttOTH,
          totalPrice: this.vehicleNetPrice,
          dcPer: this.bookingDcPer,
          dcPrice: this.bookingDcPrice,
          netPrice: this.vehicleNetPrice,
          vatPer: this.bookingVatPer,
          vatPrice: this.bookingVatPrice,

          grandTotalPrice: parseFloat(this.bookingGrandTotalPrice),
          saleCondition: this.saleCondition.scId,
          downPayment: this.downPayment,
          totalFinancing: this.totalFinancing,
          fiId: this.fiId.fiId,
          interest: this.interest,
          numPeriods: this.numPeriods,
          installment: this.installment,
          note: this.note,
          prodId: this.prodId.prodId,
          actId: this.activity.actId,
          exteriorColorId: this.exteriorColorId.exteriorColorId,
          interiorColorId: this.interiorColorId.interiorColorId,
          campaignId: this.campaignId.campaignId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "d-product-sell",
            // params: {
            //   bookingId: response.data.bookingId,
            // },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },

    updateDetail: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/approve-credit-inquire/update", {
          inquireId: atob(this.$route.params.inquireId),
          approveNote: this.approveNote,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton true
        });
    },
    submitDetail: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/approve-credit-inquire/submit", {
          inquireId: atob(this.$route.params.inquireId),
          status:
            this.creditStatus != null ? this.creditStatus.status : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          //   this.showDetail();
          this.$router.push({ name: "approve-credit-inquire" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton true
        });
    },
    // uploadImage: function() {
    //   this.overlayFlag = true; //skeleton true
    //   const bodyFormData = new FormData();
    //   bodyFormData.append("inquireId", atob(this.$route.params.inquireId));
    //   bodyFormData.append("image", this.image);
    //   useNetw
    //     .post("api/approve-credit-inquire/upload", bodyFormData)
    //     .then((response) => {
    //       Swal.fire(
    //         appConfig.swal.title.postSuccess,
    //         JSON.stringify(response.data.message),
    //         appConfig.swal.type.success
    //       );
    //       this.showDetail();
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(
    //           err.response.data.message +
    //             "<br>" +
    //             err.response.data.validationMessage
    //         ),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false; //skeleton true
    //     });
    // },
    sendingEvent(file, xhr, formData) {
      formData.append("inquireId", atob(this.$route.params.inquireId));
    },
    getImage() {
      this.overlayFlag = true;
      useNetw
        .get("api/approve-credit-inquire/file", {
          params: {
            inquireId: atob(this.$route.params.inquireId),
          },
        })
        .then((response) => {
          this.rowsImage = response.data.images;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    delImage(fileId) {
      this.overlayFlag = true;
      useNetw
        .delete("api/approve-credit-inquire/file/delete", {
          data: {
            inquireId: atob(this.$route.params.inquireId),
            fileId: fileId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getImage();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br/>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getMessage() {
      this.overlayFlag = true;
      useNetw
        .get("api/approve-credit-inquire/message", {
          params: {
            inquireId: atob(this.$route.params.inquireId),
          },
        })
        .then((response) => {
          this.rowsMessage = response.data.message;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    addMessage: function() {
      this.overlayFlag = true; //skeleton true
      const bodyFormData = new FormData();
      bodyFormData.append("inquireId", atob(this.$route.params.inquireId));
      bodyFormData.append("message", this.textMessage);
      bodyFormData.append("type", "T");
      useNetw
        .post("api/approve-credit-inquire/message/store", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          this.$refs["modalMessage"].hide();
          this.getMessage();
          this.textMessage = null;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton true
        });
    },
    getData: function() {
      this.loading = true;
      useNetw
        .get("api/credit-inquire/branch-vehicle-booking", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.filter.userBranchId != null
                ? this.filter.userBranchId.branchId
                : undefined,
            bookingCode: this.filter.bookingCode,
            inquireCode: this.filter.inquireCode,
            customer: this.filter.customer,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            status:
              this.filter.status != null
                ? this.filter.status.status
                : undefined,
          },
        })
        .then((response) => {
          this.rowsPO = response.data.data;
          this.rowsPO.to = response.data.to;
          this.rowsPO.from = response.data.from;
          this.rowsPO.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br/>" +
                err.response.data.validationMessage
            ),
            // appConfig.swal.title.Unauthorization,
            appConfig.swal.type.error
            // appConfig.swal.title.Unauthorization
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rowsPO) {
      this.bookingId = rowsPO[0].bookingId;
      this.$refs["modalCredit"].show();
    },
    submitCredit() {
      this.loading = true;
      useNetw
        .post("api/credit-inquire/store", {
          branchId:
            this.filter.userBranchId != null
              ? this.filter.userBranchId.branchId
              : undefined,
          bookingId: this.bookingId,
          inquireNote: this.inquireNoteCreate,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalCredit"].hide();
          this.$router.push({
            name: "detail-credit-inquire",
            params: { inquireId: btoa(response.data.inquireId) },
          });
          //   this.showDetail();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          if (this.$route.params.inquireId) {
            this.showDetail();
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    handleSearch() {
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>
<style>
.alert-fixed {
  position: fixed;
  top: 50px;
  left: 65%;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}

.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="card">
        <div
          class="col-12 badge"
          style="background-color: #26B99A"
          v-if="this.statusInquire === 'APV'"
        >
          <h4 class="mt-2 text-white">
            อนุมัติแล้ว
          </h4>
        </div>
        <div
          class="col-12 badge"
          style="background-color: #50a5f1"
          v-if="this.statusInquire === 'SUBMIT'"
        >
          <h4 class="mt-2 text-white">
            รออนุมัติ
          </h4>
        </div>
        <div
          class="col-12 badge"
          style="background-color: rgb(47 93 223)"
          v-if="this.statusInquire === 'RQDOC'"
        >
          <h4 class="mt-2 text-white">
            ขอเอกสารเพิ่มเติม
          </h4>
        </div>
        <div
          class="col-12 badge"
          style="background-color: #e74646"
          v-if="this.statusInquire === 'CAN'"
        >
          <h4 class="mt-2 text-white">
            ยกเลิกแล้ว
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="this.$route.params.inquireId">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-5">
                      <div class="row">
                        <!-- <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            จากสาขา:
                            <multiselect
                              v-model="userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              disabled
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div> -->

                        <div class="col-12 col-sm-6 col-md-7">
                          <div class="mb-3 position-relative">
                            รหัสสอบถาม:
                            <b-form-input
                              v-model="inquireCode"
                              type="search"
                              disabled
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-5">
                          <div class="mb-3 position-relative">
                            วันที่สอบถาม:
                            <date-picker
                              v-model="inquireDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              disabled
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="mb-3 position-relative">
                            การสอบถาม <code>(ข้อมูลเครดิต)</code>:
                            <b-form-textarea
                              v-model="inquireNote"
                              rows="6"
                              max-rows="6"
                              disabled
                              placeholder="หมายเหตุ(สอบถามข้อมูลเครดิต)"
                            ></b-form-textarea>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                          <div class="mb-3 position-relative">
                            ผลการสอบถาม <code>(ข้อมูลเครดิต)</code>:
                            <b-form-textarea
                              v-model="approveNote"
                              rows="6"
                              max-rows="6"
                              placeholder="หมายเหตุ(อนุมัติสอบถามข้อมูลเครดิต)"
                            ></b-form-textarea>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <b-button
                            class="btn m-1"
                            :disabled="statusInquire == 'APV'"
                            variant="success"
                            @click="updateDetail()"
                          >
                            บันทึก
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-7">
                      <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
                        <div class="card">
                          <div class="px-lg-2 chat-users">
                            <div class="chat-conversation p-3">
                              <simplebar
                                style="max-height: 455px"
                                id="containerElement"
                                ref="current"
                              >
                                <ul class="list-unstyled mb-0">
                                  <li class="chat-day-title">
                                    <!-- v-if="nowDate != toDate" -->
                                    <!-- <div class="title">{{ toDate }}</div> -->
                                    <div class="title">ข้อความ</div>
                                  </li>
                                  <!-- <li class="chat-day-title" v-else>
                                    <div class="title">ล่าสุด</div>
                                  </li> -->
                                  <li
                                    v-for="data of rowsMessage"
                                    :key="data.message_id"
                                    :class="{
                                      right: `${data.createdBy}` != userId,
                                    }"
                                  >
                                    <div class="conversation-list">
                                      <div class="ctext-wrap">
                                        <div class="ctext-wrap-content">
                                          <h5
                                            class="font-size-14 conversation-name"
                                          >
                                            <a href="#" class="text-dark">{{
                                              data.createdBy
                                            }}</a>
                                            <!-- <span
                              class="d-inline-block font-size-12 text-muted ms-2"
                              >{{ data.created_at }}</span
                            > -->
                                          </h5>
                                          <p class="mb-0">{{ data.message }}</p>
                                        </div>

                                        <b-dropdown
                                          class="align-self-start"
                                          variant="white"
                                        >
                                          <template v-slot:button-content>
                                            <i class="uil uil-ellipsis-v"></i>
                                          </template>
                                          <a class="dropdown-item" href="#"
                                            >Copy</a
                                          >
                                          <a class="dropdown-item" href="#"
                                            >Save</a
                                          >
                                          <a class="dropdown-item" href="#"
                                            >Forward</a
                                          >
                                          <a class="dropdown-item" href="#"
                                            >Delete</a
                                          >
                                        </b-dropdown>
                                      </div>
                                      <a
                                        class="mx-4"
                                        style="
                                          font-size: 9px;
                                          color: gray;
                                          margin-top: 0;
                                        "
                                      >
                                        {{ data.created_at }}
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </simplebar>
                            </div>
                          </div>

                          <div class="p-3 chat-input-section">
                            <div class="row">
                              <div class="col">
                                <div class="position-relative">
                                  <input
                                    type="text"
                                    v-model="textMessage"
                                    class="form-control chat-input rounded"
                                    placeholder="Enter Message..."
                                    @keyup.enter="addMessage()"
                                  />
                                </div>
                              </div>
                              <div class="col-auto">
                                <button
                                  type="submit"
                                  class="btn btn-primary chat-send w-md waves-effect waves-light"
                                  @click="addMessage()"
                                >
                                  <span class="d-none d-sm-inline-block me-2"
                                    >ส่ง</span
                                  >
                                  <i class="mdi mdi-send float-end"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card">
                        <div class="row">
                          <div class="col-lg-6">
                            <h5 class="m-3">ข้อความ</h5>
                          </div>
                          <div class="col-lg-6 text-end mb-2">
                            <button
                              v-b-modal.modalMessage
                              class="btn btn-primary"
                            >
                              พิมพ์ข้อความ
                            </button>
                          </div>
                        </div>
                        <div
                          class="table-responsive"
                          v-if="this.rowsMessage.length > 0"
                        >
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">ข้อความ</th>
                                <th scope="col">ผู้ส่ง</th>
                                <th scope="col">วันที่/เวลา</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in rowsMessage"
                                :key="index"
                              >
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ data.message }}</td>
                                <td>{{ data.createdBy }}</td>
                                <td>{{ data.created_at }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else>
                          <h5 class="text-center">ไม่พบข้อมูล</h5>
                        </div>
                      </div> -->
                    </div>

                    <!-- <div class="col-12 col-md-12 col-sm-12">
                      <div class="mb-3 position-relative">
                        <h4 class="card-title">
                          แนบเอกสาร
                          <span class="badge bg-danger">{{
                            rowsImage.length
                          }}</span>
                        </h4>
                        <hr />
                        <vue-dropzone
                          id="image"
                          ref="image"
                          :use-custom-slot="true"
                          :options="dropzoneOptions"
                          v-on:vdropzone-success="getImage"
                          v-on:vdropzone-sending="sendingEvent"
                        >
                          <div class="dropzone-custom-content">
                            <i
                              class="display-4 text-muted bx bxs-cloud-upload"
                            ></i>
                            <h4>อัพโหลดไฟล์ภาพ</h4>
                          </div>
                        </vue-dropzone>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-6 col-md-3 col-sm-3"
                        v-for="(data, index) in rowsImage"
                        :key="index"
                      >
                        <div class="mb-3 position-relative text-center">
                          <img
                            :src="data.thumbnailPath"
                            alt=""
                            class="img-fluid img-thumbnail"
                          />
                        </div>
                        <div class="mb-3 position-relative text-center">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            @click="delImage(data.file_id)"
                          >
                            <i class="uil uil-trash font-size-14"></i>
                          </a>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="col-md-12">
                      <b-button
                        class="btn float-end m-1"
                        :disabled="
                          statusInquire == 'APV' || statusInquire == 'SUBMIT'
                        "
                        variant="success"
                        @click="updateDetail()"
                      >
                        บันทึก
                      </b-button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card">
            <div class="card-body">
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-lg-6">
                      <h5>รายละเอียดข้อความ</h5>
                    </div>
                    <div class="col-lg-6 text-end mb-2">
                      <button v-b-modal.modalMessage class="btn btn-primary">
                        เพิ่มข้อความ
                      </button>
                    </div>
                  </div>
                  <div
                    class="table-responsive"
                    v-if="this.rowsMessage.length > 0"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">ข้อความ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in rowsMessage" :key="index">
                          <th scope="row">{{ index + 1 }}</th>
                          <td>{{ data.message }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>
                    <h5 class="text-center">ไม่พบข้อมูล</h5>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="card">
            <div class="card-body" v-if="this.$route.params.inquireId" style="z-index: 2;">
              <categoryUploadComponent
                :paramsIdStr="this.$route.params.inquireId"
                :urlShow="urlShow"
                :urlUpload="urlUpload"
                :paramsShow="paramsShow"
                :paramsDel="paramsDel"
                :paramsSending="paramsSending"
                :urlFullsize="urlFullsize"
                :paramsFullsize="paramsFull"
                :urlDelete="urlDelete"
              ></categoryUploadComponent>
            </div>
          </div>

          <div class="card" v-if="this.$route.params.inquireId" style="z-index: 1;">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สาขา:<br />
                        <multiselect
                          v-model="branch"
                          :options="localData"
                          disabled
                          label="nameTh"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          v-model="nameTh"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="auto"
                          :custom-label="customLabelCustomer"
                          disabled
                          placeholder=""
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ :
                        <date-picker
                          v-model="bookingDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          disabled
                        ></date-picker>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> กิจกรรมการขาย :
                        <multiselect
                          v-model="activity"
                          label="nameTh"
                          type="search"
                          :options="rowsAct"
                          open-direction="bottom"
                          placeholder="กิจกรรมการขาย"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> รหัสสินค้า :
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="prodId.prodCode"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <code> * </code> ชื่อสินค้า :
                        <multiselect
                          @input="setProduct(prodId)"
                          v-model="prodId"
                          label="nameEn"
                          type="search"
                          :options="rowProducts"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          disabled
                          :custom-label="customLabelProduct"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary" disabled>
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-11">
                      <div class="mb-3 position-relative">
                        <code> * </code> รถคันที่เลือก :
                        <multiselect
                          v-model="vehicleId"
                          label="nameEn"
                          type="search"
                          :options="rowsVm"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          disabled
                          :custom-label="customLabelVehicle"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary" disabled>
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายนอก :
                        <multiselect
                          v-model="exteriorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsExColor"
                          open-direction="bottom"
                          placeholder="สีภายนอก"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายใน :
                        <multiselect
                          v-model="interiorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsInColor"
                          open-direction="bottom"
                          placeholder="สีภายใน"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> แคมเปญ :
                        <multiselect
                          v-model="campaignId"
                          label="nameTh"
                          type="search"
                          :options="rowsCampiagn"
                          open-direction="bottom"
                          placeholder="แคมเปญ"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button class="btn btn-primary" disabled>
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>
                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ราคาสินค้า (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleTotalPrice"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> ส่วนลด (%) :
                        <input
                          type="number"
                          @change="calProd()"
                          style="text-align:right"
                          v-model="vehicleDcPer"
                          disabled
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ส่วนลด (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleDcPrice"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สุทธิ (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="vehicleNetPrice"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงื่อนไขการขาย :
                        <multiselect
                          v-model="saleCondition"
                          label="scName"
                          type="search"
                          :options="rowSaleCondition"
                          open-direction="bottom"
                          placeholder="เงื่อนไขการขาย"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงินดาวน์ (บาท) :
                        <input
                          @change="calProd()"
                          type="number"
                          style="text-align:right"
                          v-model="downPayment"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดจัดไฟแนนซ์ (บาท) :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="totalFinancing"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ชำระงวดแรก :
                        <date-picker
                          v-model="installmentDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          disabled
                        ></date-picker>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สถาบันการเงิน :
                        <multiselect
                          v-model="fiId"
                          label="nameTh"
                          type="search"
                          :options="rowsFin"
                          open-direction="bottom"
                          placeholder="สถาบันการเงิน"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> ดอกเบี้ย/เดือน:
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="interest"
                          disabled
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> จำนวนงวด :
                        <input
                          @change="calProd()"
                          min="0"
                          type="number"
                          style="text-align:right"
                          v-model="numPeriods"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดชำระต่องวด (บาท):
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="installment"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <hr />
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ผู้แนะนำ :
                        <multiselect
                          v-model="recommenderId"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="bottom"
                          @search-change="getRecommenderId"
                          @remove="getRecommenderId"
                          :custom-label="customLabelCustomer"
                          placeholder=""
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ค่านำพา :
                        <input
                          type="number"
                          style="text-align:right"
                          v-model="referralFee"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งทะเบียน:<br />
                        <multiselect
                          v-model="licensePlateDelivery"
                          :options="postType"
                          label="nameTh"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งรถ: <br />
                        <multiselect
                          v-model="productDelivery"
                          :options="postType"
                          label="nameTh"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ประเภทภาษี: <br />
                        <multiselect
                          v-model="taxType"
                          :options="taxTypeOptions"
                          label="nameTh"
                          disabled
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="mb-3 position-relative">
                        สถานที่จัดส่ง :
                        <multiselect
                          v-model="addrId"
                          label="nameTh"
                          type="search"
                          :options="rowsArr"
                          :show-labels="false"
                          open-direction="bottom"
                          :custom-label="customLabelCustomerArr"
                          disabled
                          placeholder=""
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-sm-8">
                      <div class="mb-3 position-relative">
                        หมายเหตุ :
                        <textarea
                          v-model="note"
                          class="form-control"
                          name="textarea"
                          rows="5"
                          disabled
                        ></textarea>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" style="z-index: 1;">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <p class="card-title-desc">รายการสินค้า</p>
            </div>
            <div class="col-6"></div>
          </div>
          <b-tabs
            justified
            nav-class="nav-tabs-custom"
            content-class="p-3 text-muted"
          >
            <b-tab active @click="changeType('ACCY')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">อุปกรณ์เสริม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.qnAccy.length > 0">
                      <tr v-for="(anAccyItem, index) in qnAccy" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.qnAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
            </b-tab>

            <b-tab @click="changeType('PM')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ของแถม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.qnPm.length > 0">
                      <tr v-for="(anAccyItem, index) in qnPm" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            disabled
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.qnPm.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
              <!-- <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารอุปกรณ์เสริม ,ของแถม</label
                    >
                    <multiselect
                      :disabled="statusQn != 'DFT'"
                      id="accyCode"
                      v-model="accyCode"
                      :options="rowsAccy"
                      label="partId"
                      :custom-label="customLabelAccy"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModal(accyCode)"
                      :close-on-select="true"
                      :internal-search="true"
                    >
                    </multiselect>
                  </div>
                </div>
              </div> -->
            </b-tab>

            <b-tab @click="changeType('OTH')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ค่าใช้จ่ายอื่นๆ </span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสค่าใช้จ่าย</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.qnOth.length > 0">
                      <tr v-for="(anAccyItem, index) in qnOth" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.extraCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.extraNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' || statusQn != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.qndId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePQnDt(anAccyItem)"
                            @change="calculatePQnDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePQnDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusQn == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDelAccy(qnDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.qnOth.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารายการค่าใช้จ่ายอื่นๆ
                    </label>
                    <multiselect
                      :disabled="statusQn != 'DFT'"
                      id="accyCode"
                      v-model="exCode"
                      :options="rowsExtra"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModalEx(exCode)"
                      :close-on-select="true"
                      :internal-search="true"
                    >
                    </multiselect>
                  </div>
                </div>
              </div> -->
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ผู้ค้ำประกัน</span>
              </template>
              <b-table hover :items="rowsGuarantor" :fields="fieldsGuarantor">
                <template #cell(index)="rowsGuarantor">
                  {{ rowsGuarantor.index + 1 }}
                </template>
                <!-- <template #cell(action)="rowsGuarantor">
                  <a
                    href="javascript:void(0);"
                    class="px-2 text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="confirmDelGuarantor(rowsGuarantor.item.guarantorId)"
                  >
                    <i class="uil uil-trash font-size-14"></i>
                  </a>
                </template> -->
              </b-table>
              <div class="row">
                <span
                  v-if="this.rowsGuarantor.length === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
              </div>
              <!-- <div class="row">
                <div class="col-6">
                  <div class="mb-3 position-relative">
                    เพิ่มผู้ค้ำประกัน :
                    <multiselect
                      v-model="customerG"
                      label="nameTh"
                      type="search"
                      :options="rowsCustomers"
                      :show-labels="false"
                      open-direction="bottom"
                      :custom-label="customLabelCustomer"
                      @input="postGuarantor(customerG)"
                      placeholder=""
                    >
                    </multiselect>
                  </div>
                </div>
              </div> -->
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ค่างวด</span>
              </template>

              <div class="table-responsive">
                <b-table hover :items="rowsInst" :fields="fieldsInst">
                  <template #cell(dueDate)="rowsInst">
                    <changeDate :date="rowsInst.item.dueDate"></changeDate>
                  </template>
                  <!-- <template #cell(action)="rowsInst">
                    <a
                      href="javascript:void(0);"
                      class="px-2 text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="conformDelInst(rowsInst.item.iperiodId)"
                    >
                      <i class="uil uil-trash font-size-14"></i>
                    </a>
                  </template> -->
                </b-table>
              </div>
              <div class="row">
                <button
                  class="btn btn-primary text-center"
                  v-if="rowsInst.length < 1"
                >
                  <i class="uil uil-calender"></i>
                  ประมวลผลค่างวด
                </button>
              </div>
            </b-tab>
          </b-tabs>

          <div class="card-body">
            <h4 class="card-title">
              <h4>
                <strong>สรุปยอด</strong>
              </h4>
            </h4>
            <hr />
            <div class="row">
              <b>ยอดรวมค่าสินค้า</b>
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  ราคาสินค้า (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="vehicleTotalPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleTotalPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleTotalPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleTotalPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-2 col-md-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    type="number"
                    @change="calTotal()"
                    style="text-align: right"
                    v-model="vehicleDcPer"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="vehicleDcPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="vehicleNetPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.vehicleNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าอุปกรณ์เสริม</b>
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าอุปกรณ์เสริม (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="ttAccy"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.ttAccy.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttAccy.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttAccy.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-2 col-md-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    type="number"
                    @change="calTotal()"
                    style="text-align: right"
                    v-model="partDcPer"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.partDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="partDcPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.partDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="partNetPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.partNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าอุปกรณ์เสริม</b>
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าอุปกรณ์เสริม (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="ttOTH"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.ttOTH.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttOTH.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttOTH.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-2 col-md-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    type="number"
                    @change="calTotal()"
                    style="text-align: right"
                    v-model="extraDcPer"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.extraDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="extraDcPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.extraDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    type="number"
                    style="text-align: right"
                    v-model="extraNetPrice"
                    class="form-control"
                    disabled
                    :class="{
                      'is-invalid': submitform && $v.extraNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <b
                  >รายละเอียด <br />
                  ใบเสนอขายรถ</b
                >
              </div>
              <div class="col-5 col-sm-4 col-md-3" style="padding: 0px;">
                <div style="display: inline-block">
                  <div class="text-end">
                    ยอดรวม : <br />
                    ส่วนลดรวม(%) : <br />
                    ส่วนลดรวม(บาท) : <br />
                    ภาษีรวม(%) : <br />
                    ภาษีรวม(บาท) : <br />
                    สุทธิ : <br />
                    เงินดาวน์ : <br />
                    ยอดจัดไฟแนนซ์ :
                  </div>
                </div>
              </div>
              <div class="col-5 col-sm-4 col-md-2 text-end">
                {{ Number(qnTotalPrice).toFixed(2).toLocaleString() }}
                <br />
                {{ Number(qnDcPer).toFixed(2) }}<br />
                {{ Number(qnDcPrice).toFixed(2).toLocaleString() }}
                <br />
                {{ parseFloat(Number(vatPer)).toFixed(2) }} <br />
                {{ Number(qnVatPrice).toLocaleString() }} <br />
                {{ Number(parseFloat(qnGrandTotalPrice)).toLocaleString() }}
                <br />
                {{ Number(downPayment).toLocaleString() }} <br />
                {{ Number(totalFinancing).toLocaleString() }}
              </div>
              <div class="col-2 col-sm-2 col-md-1 text-end">
                บาท<br />
                %<br />
                บาท <br />
                % <br />
                บาท <br />
                บาท <br />
                บาท <br />
                บาท
              </div>
              <!-- มูลค่ารวม -->
              <div
                class="col-12 col-sm-12 col-md-6 bg-dark text-white m-0"
                style="border-radius:10px;margin:auto;justify; padding: 0px;"
              >
                <div class="row align-items-center m-0 text-end">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-4 col-sm-4 col-md-3" style="padding: 0px;">มูลค่ารวม :</div>
                      <div class="col-6 col-sm-6 col-md-6 font-size-22" style="padding: 0px;">
                        {{
                          Number(
                            parseFloat(this.qnGrandTotalPrice)
                          ).toLocaleString()
                        }}
                      </div>
                      <div class="col-2 col-sm-2 col-md-3">บาท</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />
            <div class="row">
              <hr />
              <div class="text-end col-7 col-sm-7 col-md-9"></div>
              <div class="col-12 col-sm-5 col-md-3">
                <!-- <code v-show="statusQn != 'DFT'"
                  >ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว
                </code> -->
                <div class="mb-3 position-relative">
                  <code> * </code>สถานะ:
                  <multiselect
                    v-model="creditStatus"
                    label="nameTh"
                    type="search"
                    :options="optionStatus"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    :class="{
                      'is-invalid': submitform && $v.creditStatus.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.creditStatus.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.creditStatus.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
                <div class="text-end">
                  <button class="btn btn-danger" @click="tooltipFormSubmit()">
                    บันทึกผลข้อมูลเครดิต
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <!-- //? Modal select Part -->
    <b-modal ref="modalAmout" title="ระบุจำนวน" hide-footer size="sm" centered>
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> จำนวน</label>
            <span>({{ this.part.nameTh }})</span>
            <input
              :state="nameState"
              id="amount"
              v-model="amountAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-11" v-show="false">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> %ส่วนลด</label>
            <input
              disabled
              :state="nameState"
              id="amount"
              v-model="dcPerAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
        <div class="col-1" v-show="false">
          &nbsp; <br />
          <br />
          %
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="postAccy(part)" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->

    <!-- //? Modal select Part -->
    <b-modal
      ref="modalAmoutEx"
      title="ระบุจำนวน"
      hide-footer
      size="sm"
      centered
    >
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> จำนวน</label>
            <span>({{ this.ex.nameTh }})</span>
            <input
              :state="nameState"
              id="amount"
              v-model="amountAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-11" v-show="false">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> %ส่วนลด</label>
            <input
              disabled
              :state="nameState"
              id="amount"
              v-model="dcPerAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
        <div class="col-1" v-show="false">
          &nbsp; <br />
          <br />
          %
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="postEx(ex)" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalMessage"
      id="modalMessage"
      title="รายละเอียดข้อความเพิ่มเติม"
      hide-footer
      size="lg"
      centered
      :state="nameState"
    >
      <div class="row">
        <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
          <label> หมายเหตุ <code>(ข้อความเพิ่มเติม)</code></label>
          <b-form-textarea
            id="textarea"
            v-model="textMessage"
            placeholder="หมายเหตุข้อความเพิ่มเติม"
            rows="10"
            max-rows="10"
          ></b-form-textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="addMessage()" class="btn btn-primary">
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modalCredit"
      title="สร้างสอบถามข้อมูลเครดิต"
      hide-footer
      size="lg"
      centered
      :state="nameState"
    >
      <div class="row">
        <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
          <label> หมายเหตุ</label>
          <b-form-textarea
            id="textarea"
            v-model="inquireNoteCreate"
            placeholder="หมายเหตุสอบถามข้อมูลเครดิต"
            rows="10"
            max-rows="10"
          ></b-form-textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="submitCredit()" class="btn btn-primary">
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->
  </Layout>
</template>
